const FIXED_DELIVERY_COST = 10;

const getVariableDeliveryCost = size => {
	switch (true) {
		case size < 1000:
			return 25;
		case size < 2000:
			return 35;
		case size < 3000:
			return 50;
		case size < 4000:
		case size < 5000:
			return 60;
		case size < 6000:
		case size < 7000:
		case size < 8000:
			return 65;
		case size < 9000:
			return 85;
		case size <= 10000:
			return 90;
		default:
			throw new Error('Package weight exceeds maximum');
	}
};

export const getDeliveryPrice = size =>
	getVariableDeliveryCost(size) + FIXED_DELIVERY_COST;
