import React, { Component } from 'react';
import {
	GlobalDispatchContext,
	GlobalStateContext,
} from '../context/globalContextProvider';
import { getErrorMessage, isEmail, isPhone } from '../validation';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AddressForm from '../components/addressForm';
import CheckoutForm from '../components/checkoutForm';
import CartSection from '../components/cartSection';

import styles from './checkout.module.css';
import { navigate } from 'gatsby';

class CheckoutPage extends Component {
	state = {
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		street: '',
		city: '',
		subDistrict: '',
		district: '',
		zip: '',
		delivery: 'registered',
		errors: {},
		loader: false,
	};

	validate = (key, value) => {
		const errors = this.state.errors;
		const errorName = key + 'Error';
		if (Object.keys(errors).length !== 0 && !errors[errorName]) return;
		else if (
			(key === 'email' && !isEmail(value)) ||
			(key === 'phone' && !isPhone(value)) ||
			(key === 'zip' && isNaN(value) && value.length >= 1)
		) {
			errors[errorName] = getErrorMessage(key);
			return this.setState(errors);
		} else if (value.length < 3) {
			errors[errorName] = 'Please enter 3 or more characters';
			return this.setState(errors);
		}
		delete errors[errorName];
		this.setState({ errors });
	};

	handleBlur = e => {
		const value = e.target.value;
		const key = e.target.name;
		this.validate(key, value);
	};

	handleChange = e => {
		const value = e.target.value;
		const key = e.target.name;
		this.validate(key, value);
		this.setState({ [key]: value });
	};

	handleSubmit = (e, dispatch) => {
		e.preventDefault();
		const items = this.context.items.map(item => ({
			id: item.id,
			quantity: item.amount,
		}));
		const { delivery, errors } = this.state;
		const client = { ...this.state };
		delete client.errors;
		delete client.loader;
		delete client.delivery;
		const data = { items, client, delivery };

		if (Object.keys(errors).length === 0) {
			this.setState({ loader: true });
			this.postData(
				'https://jwy9mtect0.execute-api.us-east-1.amazonaws.com/dev/checkout',
				data
			)
				.then(navigate('/thank-you'))
				.catch(() => {
					navigate('/');
					dispatch({
						type: 'CREATE_TOPBAR',
						message: 'An error occured. Your order could not be processed',
					});
				});
		}
	};

	async postData(url = '', data = {}) {
		return await fetch(url, {
			method: 'POST',
			mode: 'no-cors',
			body: JSON.stringify(data),
		});
	}

	static contextType = GlobalStateContext;

	render() {
		const globalState = this.context;
		const { items } = globalState;
		if (
			items.length < 1 &&
			typeof window !== 'undefined' &&
			window.localStorage
		)
			navigate('/');

		return (
			<GlobalDispatchContext.Consumer>
				{dispatch => (
					<Layout>
						<SEO title="Checkout" />
						{this.state.loader && (
							<div className={styles.loaderWrapper}>
								<div className={styles.loader}>
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</div>
						)}
						<form
							name="checkout"
							method="POST"
							onSubmit={e => this.handleSubmit(e, dispatch)}
						>
							<div className={styles.wrapper}>
								<div className={styles.leftCol}>
									<AddressForm
										inputs={this.state}
										errors={this.state.errors}
										handleChange={this.handleChange}
										handleBlur={this.handleBlur}
									/>
									<CartSection items={items} />
								</div>
								<CheckoutForm
									items={items}
									className={styles.rightCol}
									delivery={this.state.delivery}
									handleChange={this.handleChange}
									errors={this.state.errors}
								/>
							</div>
						</form>
					</Layout>
				)}
			</GlobalDispatchContext.Consumer>
		);
	}
}

export default CheckoutPage;
