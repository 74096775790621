import React, { Component } from 'react';

import styles from './addressForm.module.css';

class AddressForm extends Component {
	render() {
		const {
			firstName,
			lastName,
			email,
			phone,
			street,
			subDistrict,
			district,
			city,
			zip,
		} = this.props.inputs;
		const { handleChange, handleBlur, errors } = this.props;
		const {
			firstNameError,
			lastNameError,
			emailError,
			phoneError,
			streetError,
			subDistrictError,
			districtError,
			cityError,
			zipError,
		} = errors || '';

		return (
			<section className={styles.section}>
				<h2 className={styles.title}>เพิ่มที่อยู่ของคุณ</h2>
				<div className={styles.row}>
					<div className={styles.col}>
						<label className={styles.label} htmlFor="firstName">
							<span>ชื่อ</span>
							<span className={styles.errorMessage}>{firstNameError}</span>
						</label>
						<input
							className={styles.input}
							type="text"
							placeholder="ชื่อของคุณ"
							id="firstName"
							name="firstName"
							value={firstName}
							onChange={handleChange}
							onBlur={handleBlur}
							required
						/>
					</div>

					<div className={styles.col}>
						<label className={styles.label} htmlFor="lastName">
							<span>นามสกุล</span>
							<span className={styles.errorMessage}>{lastNameError}</span>
						</label>
						<input
							className={styles.input}
							type="text"
							placeholder="นามสกุลของคุณ"
							id="lastName"
							name="lastName"
							value={lastName}
							onChange={handleChange}
							onBlur={handleBlur}
							required
						/>
					</div>
				</div>
				<div>
					<label className={styles.label} htmlFor="email">
						อีเมลล์
						<span className={styles.errorMessage}>{emailError}</span>
					</label>
					<input
						className={styles.input}
						type="text"
						placeholder="อีเมลล์ของคุณ"
						id="email"
						name="email"
						value={email}
						onChange={handleChange}
						onBlur={handleBlur}
						required
					/>
				</div>
				<div>
					<label className={styles.label} htmlFor="phone">
						เบอร์โทรศัพท์
						<span className={styles.errorMessage}>{phoneError}</span>
					</label>
					<input
						className={styles.input}
						type="phone"
						placeholder="เบอร์โทรศัพท์ของคุณ"
						id="phone"
						name="phone"
						value={phone}
						onChange={handleChange}
						onBlur={handleBlur}
						required
					/>
				</div>
				<div>
					<label className={styles.label} htmlFor="street">
						ที่อยู่
						<span className={styles.errorMessage}>{streetError}</span>
					</label>
					<input
						className={styles.input}
						type="text"
						placeholder="หมู่บ้านใจดี บ้านเลขที่ 123 หมู่1 ถนนสายรุ้ง"
						id="street"
						name="street"
						value={street}
						onChange={handleChange}
						onBlur={handleBlur}
						required
					/>
				</div>
				<div className={styles.row}>
					<div className={styles.col}>
						<label className={styles.label} htmlFor="subDistrict">
							ตำบล/เขต
							<span className={styles.errorMessage}>{subDistrictError}</span>
						</label>
						<input
							className={styles.input}
							type="text"
							placeholder="ตำบล/เขต"
							id="subDistrict"
							name="subDistrict"
							value={subDistrict}
							onChange={handleChange}
							onBlur={handleBlur}
							required
						/>
					</div>
					<div className={styles.col}>
						<label className={styles.label} htmlFor="district">
							อำเภอ/แขวง
							<span className={styles.errorMessage}>{districtError}</span>
						</label>
						<input
							className={styles.input}
							type="text"
							placeholder="อำเภอ/แขวง"
							id="district"
							name="district"
							value={district}
							onChange={handleChange}
							onBlur={handleBlur}
							required
						/>
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.col}>
						<label className={styles.label} htmlFor="city">
							จังหวัด
							<span className={styles.errorMessage}>{cityError}</span>
						</label>
						<input
							className={styles.input}
							type="text"
							placeholder="กรุงเทพ"
							id="city"
							name="city"
							value={city}
							onChange={handleChange}
							onBlur={handleBlur}
							required
						/>
					</div>
					<div className={styles.col}>
						<label className={styles.label} htmlFor="zip">
							รหัสไปรษณีย์
							<span className={styles.errorMessage}>{zipError}</span>
						</label>
						<input
							className={styles.input}
							type="text"
							placeholder="10110"
							id="zip"
							name="zip"
							value={zip}
							onChange={handleChange}
							onBlur={handleBlur}
							required
						/>
					</div>
				</div>
			</section>
		);
	}
}

export default AddressForm;
