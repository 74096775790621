import React from 'react';

import styles from './cartSection.module.css';

function CartSection({ items }) {
	return (
		<section>
			<h2 className={styles.title}>ตะกร้าของคุณ</h2>
			<div className={styles.row}>
				<strong className={styles.col}>สินค้าที่เลือก</strong>
				<div className={styles.col}></div>
				<strong className={styles.col}>ราคา</strong>
				<strong className={styles.col}>จำนวน</strong>
				<strong className={styles.col}>รวมเป็น</strong>
			</div>
			{items.map(item => {
				const { id, thumbnail, title, price, amount } = item;
				const total = price * amount;
				return (
					<div className={styles.row} key={id}>
						<img className={styles.col} src={thumbnail} alt={title} />
						<span className={styles.col}>{title}</span>
						<span className={styles.col}>฿ {price}</span>
						<span className={styles.col}>{amount}</span>
						<span className={styles.col}>฿ {total}</span>
					</div>
				);
			})}
		</section>
	);
}

export default CartSection;
