import React, { Component } from 'react';
import styles from './checkoutForm.module.css';
import { getDeliveryPrice, getSizeTotal } from '../modules/cart/utils';

class CheckoutForm extends Component {
	get deliveryPrice() {
		const { items } = this.props;

		try {
			const size = getSizeTotal(items);
			return getDeliveryPrice(size);
		} catch (error) {
			console.log(error);
		}
	}

	render() {
		const { items, errors } = this.props;

		const productPrice = items.reduce(
			(sum, next) => sum + next.price * next.amount,
			0
		);
		const total = productPrice + this.deliveryPrice;

		if (!total)
			return (
				<div>
					หากท่านมีความประสงค์ที่จะสั่งสินค้าที่มีน้ำหนักมากกว่า 10 กิโลกรัม
					โปรดติดต่อเจ้าหน้าที่เพื่อดำเนินการจัดซื้อให้ค่ะ ขอบคุณค่ะ
				</div>
			);

		return (
			<section className={styles.section}>
				<div className={styles.wrapper}>
					<h2 className={styles.title}>เช็คเอาท์</h2>
					<div className={styles.row}>
						<span>ราคาสินค้าทั้งหมด</span>
						<span>฿ {productPrice}</span>
					</div>
					<div className={styles.row}>
						<span>ราคาการจัดส่ง</span>
						<span>฿ {this.deliveryPrice}</span>
					</div>
					<div className={styles.row}>
						<small>
							<div>การจัดส่งสินค้า</div>
							ทางกลุ่มสตรีศรีมาลัยจะทำการจัดส่งสินค้าให้ภายใน 1-3 วัน
							นับจากวันที่คอนเฟริ์มยอดสินค้าเรียบร้อย
							<u>ระยะเวลาในการส่งสินค้า</u>
							<br />
							โดยปกติบริษัทขนส่งจะใช้เวลาประมาณ 1-3 วัน ในเขตปริมณฑล ประมาณ 3-7
							วัน ในเขตต่างจังหวัด
						</small>
					</div>
					<div>
						<label
							style={{
								display: 'block',
								fontWeight: '500',
								marginBottom: '.25rem',
							}}
							htmlFor="coupon"
						>
							คูปองส่วนลด
						</label>
						<input
							className={styles.input}
							type="text"
							placeholder="กรุณาใส่รหัสคูปองส่วนลด"
							id="coupon"
							name="coupon"
							autoComplete="off"
						/>
					</div>
					<div className={styles.row}>
						<strong>รวมเป็นราคา</strong>
						<strong>฿ {total}</strong>
					</div>
					<input
						className={styles.button}
						type="submit"
						value="ยืนยันการสั่งซื้อ"
						disabled={Object.keys(errors).length !== 0}
					/>
				</div>
			</section>
		);
	}
}
export default CheckoutForm;
