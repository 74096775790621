export function isEmail(value) {
	const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regex.test(value);
}

export function isPhone(value) {
	const regex = /^[0-9\+]{8,13}$/;
	return regex.test(value);
}

export function getErrorMessage(key) {
	switch (key) {
		case 'email':
			return 'Please enter a valid email address';
			break;
		case 'phone':
			return 'Please enter a valid phone number';
			break;
		case 'zip':
			return 'Only number are allowed';
			break;
		default:
			return '';
	}
}
